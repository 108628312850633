import React from 'react';
import VisitBottom from './visitbottom/VisitBottom';
import VisitTop from './visittop/VisitTop';

function VisitPage() {
    return (
        <div>
            <VisitTop />
            <VisitBottom />
        </div>
    )
}

export default VisitPage
